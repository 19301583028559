
(function($){
  $.stencil = function(el, options){
    // To avoid scope issues, use 'base' instead of 'this'
    // to reference this class from internal events and functions.
    var base = this;

    // Access to jQuery and DOM versions of element
    base.$el = $(el);
    base.el = el;

    // Add a reverse reference to the DOM object
    base.$el.data("stencil", base);

    base.init = function() {
      base.options = $.extend({
        modelType: base.$el.data('model-type'),
        modelID: base.$el.data('model-id'),
        list_url: base.$el.data('url'),
        modelThemeID: base.$el.data('model-theme-id'),
        formType: base.$el.data('form-type'),
        stencilID: base.$el.data('stencil-id'),
        userProfileID: base.$el.data('user-profile-id'),
        elementUID: base.$el.data('element-uid'),
        finderMethod: base.$el.data('finder-method'),
        finderID: base.$el.data('finder-id'),
        bulkUpdate: base.$el.data('bulk-update'),
        adminConfig: base.$el.data('admin-config'),
      }, $.stencil.defaultOptions, options);

      base.isChrome = /chrome/.test(navigator.userAgent.toLowerCase());
      if (base.$el.hasClass('modal') || base.$el.hasClass('preview')) base.options.disableNotes = true;

      if (!$.fluxx.stencilsLoading) $.fluxx.stencilsLoading = {};

      if (base.options.inline_data && base.options.template) {
        base.showPrintable();
        return;
      }

      if (base.options.formType == 'list' && (base.options.request || base.options.list_url)) {
        var url = options.request ? options.request.url : base.options.list_url,
            data = options.request ? options.request.data : base.$el.data('filter');
        url = url.replace(/\.json(\?.*)?/g, '') + '.json';
        if (!data) data = [];
        if ($.isArray(data)) {
          data = _.reject(data, function(d) { return d.name == 'stencil'; });
          // JWS: 10948 incorrect key name. good ones look like: grant_request[model_theme_id][]
          // MJ: 12395 handle case of single model_theme_id in card config, rename to fix
          _.each(data, function(d) {
            if(d.name == 'grant_request[model_theme_id]') {
              d.name = 'grant_request[model_theme_id][]'
            }
          });
          data.push({name: "stencil", value: true});
        } else {
          data.stencil = true;
        }
        base.loadStencil(url, data);
        return;
      }
      if (!base.options.url) base.setURL();

      if (base.options.loadingHTML) base.$el.html(base.options.loadingHTML);

      if (base.options.finderMethod && base.options.finderID) {
        base.options.data = [
          {name: 'finder_method', value: base.options.finderMethod},
          {name: 'finder_id', value: base.options.finderID}
        ];
        //AML: Hard-coded for now. Pass connect_user param so that legacy account fields component know to actually show the password
        if (base.$el.data('connect-user')) base.options.data.push({name: 'connect_user', value: 1});
      }

      base.registerLock();
      base.loadStencil(base.options.url, base.options.data);
    };

    // instead of having to pass down the ModelDslLock.time_interval with every form,
    // try to extend if the form has been open for almost 5 minutes (4.5)
    var EXTEND_LOCK_IN_MS = 4.5 * 1000 * 60;
    var LOCKED_FORM_SELECTOR = '.area.detail[data-src$="/edit"][data-model-type=":modelType"][data-model-id=":modelID"] .edit form:not([data-locked="true"])';

    base.countdownToLock = function(modelType, modelID) {
      window.setTimeout(base.extendLock.bind(this, modelType, modelID), EXTEND_LOCK_IN_MS);
    };

    base.extendLock = function(modelType, modelID) {
      var selector = LOCKED_FORM_SELECTOR.replace(":modelType", modelType).replace(":modelID", modelID);
      var isStillEditingModel = $.my.cards.find(selector).length;
      if (!isStillEditingModel) return;

      var lockUrl = ['/lock', modelType, modelID].join('/');
      $.get(lockUrl);
      base.countdownToLock(modelType, modelID);
    };

    base.registerLock = function() {
      var formType  = base.options.formType,
          modelType = base.options.modelType,
          modelID   = base.options.modelID;

      if (formType != 'form' || !modelType || !modelID) return;
      base.countdownToLock(modelType, modelID);
    };

    base.setURL = function() {
      var target_model_id = $("#target_model_id").val();
      if(target_model_id && base.options.modelID) {
        base.options.modelID = target_model_id;
      }
      if (base.options.modelThemeID && !base.options.modelID) {
        base.options.url = '/stencil_by_theme/' + base.options.modelThemeID;
      } else if (base.options.elementUID) {
        base.options.url = ['/stencil_element', base.options.stencilID, base.options.modelID, base.options.formType, base.options.elementUID].join('/');
        if (base.options.bulkUpdate) {
          base.options.url = base.options.url + "?bulk_update=" + base.options.bulkUpdate;
        }
      } else if (base.options.stencilID) {
        base.options.url = ['/stencils', 'preview', base.options.modelType, base.options.formType, base.options.modelID, base.options.stencilID].join('/');
        if (!base.options.enableInteraction) base.previewMode = true;
      } else {
        base.options.url = ['/stencils', base.options.modelType, base.options.formType, base.options.modelID].join('/');
      }
    };

    base.loadStencil = function(url, data) {
      if ($.universal_portal) {
        $.my.api.postMessage('show-loading-indicator', { url: url, data: data });
      }

      //17059 - LOI application doesn't load card.js, so there is no callPlugin
      if(base.$el.callPlugin && base.$el.callPlugin("loadStencil", url, data, base)) return;
      if (base.loading) return;
      base.lastURL = url;
      base.lastData = data;
      if (base.options.userProfileID) {
        if (!data) data = [];
        data.push({name: 'user_profile_id', value: base.options.userProfileID});
      }
      if (base.options.adhoc) {
         if (!data) data = [];
        data.push({name: 'adhoc', value: true});
      }
      if (base.options.adminConfig) {
        if (!data) data = [];
        data.push({name: 'adminConfig', value: true});
      }
      // Append language query param to pass to stencil request
      if (base.options.locale != I18n.defaultLocale) {
        if (url.match(/\?./)) {
          var separator = '&';
        } else {
          var separator = '?';
        }
        var locale = base.$el.data('lang') || I18n.locale;
        url += separator + 'lang=' + locale;
        if (base.$el.data('parent-model-class') && base.$el.data('parent-model-id')) {
          url += '&parent_model_class=' + base.$el.data('parent-model-class') + '&parent_model_id=' + base.$el.data('parent-model-id') + '&child_model=1'
        }
      }
      $.fluxx.log('Stencil URL:' + url);
      base.loading = true;
      var saveButton = $('li.submit.save-config a');
      saveButton.css({'pointer-events': 'none', 'opacity': '0.4'})
      $.get(url, data, function(data, status, xhr) {
        var isExecutiveSummary = $('.exec-summary').length > 0;
        base.loading = false;
        base.data = data;

        if (data.search_timeout) {
          handleSearchTimeout(isExecutiveSummary, data)
        } else {
          var editTransationMode = base.$el.find('.body .edit').first().hasClass('edit-translation');
          var closingTranslationMode = base.$el.find('a.to-self').hasClass('closing');
          var $selectedItem = base.$el.fluxxCard().find('.translator-detail .selected').first();
          uid = $selectedItem.children('div').first().data('uid');

          if (editTransationMode && $.fluxx.config.user_is_translator && !closingTranslationMode && uid) {
            base.resetTranslationData();
          } else {
            base.render();
          }
        }
        if (data.models && options.searchExistingCard) {
          $('body').append("<div role='alert' aria-live='polite' class='screen-reader-only search-result-message' style='overflow: hidden; height: 0; width: 0'>" + data.models.length + " results found</div>")
          setTimeout(function () {
            $('.search-result-message').remove();
          }, 2000);
          var result =  $($(base.el).find('.list li a.to-detail')[0]);
          if (result !== undefined) {
            result.focus();
          }
        }
      }).fail(function(xhr, status) {
        $.fluxx.log('Failure', status, xhr);
        if (/^\/stencils\/.+\/filter/.test(url)) {
          base.$el.fluxxCard().data('stencilError', true);
        }
        base.options.callBack(base.options.errorHTML, base.$el);
        base.loading = false;
      }).done(function() {
        saveButton.css({'pointer-events': 'auto', 'opacity': ''})
        if (base.$el.fluxxCard().data('stencilError')) {
          base.$el.fluxxCard().removeData('stencilError');
        }
      });
    };

    var handleSearchTimeout = function(isExecutiveSummary, response) {
      if (!isExecutiveSummary) {
        var timeoutHTMLFlag = base.options.timeoutHTMLFlag(response.search_timeout);
        base.options.callBack(timeoutHTMLFlag, base.$el);
      } else {
        var timeoutHTMLBanner = base.options.timeoutHTMLBanner(response.search_timeout);
        !$('#stage').find('.search-timeout-banner').length && $('#stage').prepend($(timeoutHTMLBanner));
        base.options.callBack(timeoutHTMLBanner, base.$el);
      }
    }

    base.resetTranslationData = function() {
      var $card = base.$el.fluxxCard();
      $card.callPlugin('resetTranslationData', base.$el, $card, base.data.options);
    }

    base.loadRTUListView = function() {
      var $card = base.$el.fluxxCard();
      $card.callPlugin('loadRTUListView', base.data);
    }

    base.showPrintable = function() {
      var template = {key: 'print', underscore_template: base.options.template, timestamp: 0};
      base.cacheTemplate(template);
      base.data = base.options.inline_data;
      base.options.callback = function(output, $el) {
        $el.html(output).areaDetailTransform();
      };
      base.parseTemplate('print');
    };

    base.setReadOnly = function () {
      if (base.data && base.data.read_only) {
        _.each(base.data.read_only, function(val,uid) {
          if (val) {
            var elem = base.$el.find('[data-uid="' + uid + '"]');
            var input = elem.find(':input');
            if (input.length == 0) {
              // workaround for autocomplete fields
              input = $(elem.parent().find(':input')[0]);
              if (input.length !== 0 && input.attr('class') !== undefined && input.attr('class').match(/autocomplete/)) {
                input.prop('disabled', true).data('readonly', true);
              }
            } else {
              input.prop('disabled', true).data('readonly', true);
            }
            // also remove component buttons such as + and -, pct button
            // DEM: this is a bit of whack-a-mole with components, so add more classes that should be hidden here as we find them.
            elem.find(".to-modal,.do-delete-this,.as-delete").each(function(){
              var self = $(this);
              self.find('img').hide();
              self.addClass('disabled_href').click(function(e) {
                $.fluxx.util.itEndsHere(e);
              })
            })
          }
        });
      }
      if (base.previewMode) {
        // Disable all interaction with a stencil in preview mode
        base.$el.children().click(function(e) {
          $.fluxx.util.itEndsHere(e);
          alert('Interaction is disabled when previewing a Stencil');
        });
      }
    };

    base.setupTrackableAttributes = function($area) {
      if ($.fluxx.config.portal_user) return;
      if (base.previewMode) return;
      if (base.data.trackable == null) return;

      if (!$area) $area = base.$el;

      $area.find('.trackable').each(function(e) {
        var $elem = $(this),
            attr = $elem.data('trackable-attribute');

        $elem.prepend(base.trackableLink(base.data, attr, $elem.attr('style')));
      });
    };

    base.populateNotes = function($area) {
      if (!$area) $area = base.$el;
      var notes = base.data.attribute_notes;
      if (!(base.formType() == 'form' || base.formType() == 'show')) return false;
      if (!base.data || !base.data.settings || !base.data.settings.can_add_notes) return false;
      // No card notes in list view. This version was apparently needed for MEL
      $area.find('.notable').each(function(e) {
        var $elem = $(this),
            attr = $elem.data('notable-attribute'),
            hasNote = false;

        if (!attr) return;
        hasNote = notes[attr] || notes[attr.split('.')[0]];

        if ($.fluxx.config && $.fluxx.config.current_user_id && !$.fluxx.config.portal_user) $elem.attr('title', "Backend Field Name: " + attr);

        if (notes && !base.options.disableNotes) {
          if (hasNote) $elem.addClass('has-note');
          if (hasNote == 'new') $elem.addClass('new');
          $elem.prepend(base.noteLink(hasNote, attr, $elem.attr('style')));
        }
      });
    };

    base.noteLink = function(hasNote, attr, style) {
      var editLink, createLink,
          noteImage = hasNote ?
            (hasNote == 'new' ? '/images/fluxx_engine/theme/default/icons/note_new.png' : '/images/fluxx_engine/theme/default/icons/note.png') :
            '/images/fluxx_engine/theme/default/icons/note_add.png';

      if (base.data.model) {
        if ($.fluxx.config.use_signals_for_notes) {
          editLink = '/posts?attribute_name=' + attr + '&list_posts=1&related_model_id=' + base.data.model.id + '&related_model_type=' + base.data.model.translated_class_name + '&skip_wrapper=1';
          createLink = '/posts/new?post%5Battribute_name%5D=' + attr + '&post%5Brelated_model_id%5D=' + base.data.model.id + '&post%5Brelated_model_type%5D=' + base.data.model.translated_class_name;
        } else {
          editLink = '/notes/0/edit?attribute_name=' + attr + '&list_posts=1&related_model_id=' + base.data.model.id + '&related_model_type=' + base.data.model.translated_class_name + '&skip_wrapper=1';
          createLink = '/notes/new?note%5Battribute_name%5D=' + attr + '&note%5Bnotable_id%5D=' + base.data.model.id + '&note%5Bnotable_type%5D=' + base.data.model.translated_class_name;
        }
        var link = hasNote ? editLink : createLink;
        var onSuccess = 'setHasNote,close';
        // Check if component is a child of an unsaved request, if it is, and toogle to prevent creation of children of the request is turned on, mark sticky notes connected to the component.
        if (base.data.unsaved_request_model && $.fluxx.config.prevent_creation_of_unsaved_parent_child) {
          return $(
            '<div class="sticky-note">' +
            '<a href="/grant_requests/new?inline=true&keep_mustache=true" data-on-success="' + onSuccess + ' "id="posts-' + base.data.model.id + '" class="to-modal hide-footer unsaved-parent" title="Save Required" data-balloon-pos="left" data-balloon="Save Required" aria-label="Save Required">' +
            '<img src="' + noteImage + '" alt="add comment to field" role="presentation"/>' +
            '</a></div>'
          );
        }
        return $(
          '<div class="sticky-note" aria-hidden="true">' +
          '<a href="' + link + '" title="Signal" class="to-modal wide' + (hasNote? ' has-note' : '') + '" data-on-success="' + onSuccess + '" data-edit-link="' + editLink + '" aria-label="add comment to field">' +
          '<img src="' + noteImage + '" alt="add comment to field" role="presentation"/>' +
          '</a></div>'
        );
      }
    };

    base.trackableLink = function(data, attr, style) {
      if (data.model == null) { return; }

      var count = data.trackable[attr];
      var params = {
        trackable_type: data.model.class_name,
        trackable_id: data.model.id,
        form_type: base.formType(),
        attr: attr,
      };

      if (data.trackable_workflow[attr]) {
        params['trackable_workflow'] = true;
      }
      var href = '/modifications?' + $.param(params),
          onSuccess = 'close';

      var link;
      if (count == 0) {
        link = '<a>' + count + '</a>';
      } else {
        link = '<a href="' + href + '" title="Changelog" class="to-modal wide" data-on-success="' + onSuccess + '">' + count + '</a>';
      }

      var trackable = $('<div class="trackable-icon" id="trackable-' + attr + '">' + link + '</div>');
      return trackable;

    };

    base.stencilCacheName = function() {
      return $.fluxx.config.stencil_cache_name;
    };

    base.getCache = function() {
      if (!$.my.stencilCache) $.my.stencilCache = {};
      return $.my.stencilCache;
    };

    base.cacheValid = function(stencilForm) {
      // return false; // Uncomment to disable caching
      // Always get the fresh template when previewing a stencil
      if (base.previewMode) return false;
      var cache = base.getCache();
      return cache[stencilForm.key] && cache[stencilForm.key].ts == stencilForm.timestamp;
    };

    base.getCachedTemplate = function(key) {
      if (base.getCache()[key]) return base.getCache()[key];
    };

    base.cacheTemplate = function(data) {

      var templates = [],
          cache = base.getCache();

      templates = $.parseJSON(data.underscore_template);
      if (!$.isArray(templates)) templates = [];
      cache[data.key] = {ts: data.timestamp};
      var compiledTemplates = base.recurseEval(templates);
      cache[data.key].compiled = compiledTemplates;
    };

    base.elementUids = function(uid, val) {
      if (base.formType()!='list' && !base.data) {
        return;
      }
      var key = base.formType() == 'list' ? 'list' : base.data.stencil_form.key;
      if (!$.my.elementUids) $.my.elementUids = {};
      if (!$.my.elementUids[key]) $.my.elementUids[key] = {};
      if (val == undefined) {
        if (uid) {
          return $.my.elementUids[key][uid];
        } else {
          return $.my.elementUids[key];
        }
      } else {
        $.my.elementUids[key][uid] = val;
      }
    };

    base.recurseEval = function(templates, uid) {
      var output = [];

      // use for loops for speed, see http://jsperf.com/jquery-each-vs-each-vs-for-loop/16
      for (var i = 0, len = templates.length; i < len; i++) {
        if ($.isArray(templates[i])) {
          if ($.isArray(templates[i][1])) {
            output[i] = base.recurseEval(templates[i][1], templates[i][0]);
          } else {
            output[i] = base.safeEval(templates[i][1], templates[i][0], templates[i][2]);
          }
          base.elementUids(templates[i][0], {type: templates[i][2], compiled: output[i]});
        } else {
          output[i] = base.safeEval(templates[i], uid, 'group');
        }
      }
      return output;
    };

    base.safeEval = function(templateString, uid, type) {
      try {
        var evaluate = (new Function( 'return ' + templateString ))();
        return {code: evaluate, uid: uid};
      } catch(err) {
        $.fluxx.log('------------------------------------------------------------------------------------', 'An evaluation error has occurred on element ' + uid);
        if (uid) $.fluxx.log('Element type: ' + type);
        //AML: Can't seem to get around the jslint with rule, replace 'with' with if
        var functionString = "var evaluate = " + templateString.replace('with(obj||{})', 'if(true)') +";";
        JSLINT(functionString);
        for(var i = 0; i < JSLINT.errors.length - 2; i++){
          var error = JSLINT.errors[i];
          if(error && error.reason != "Unnecessary semicolon."){
            error.line++;
            $.fluxx.log(error.reason + ' Line: ' + error.line + ' Character: ' + error.character);
            $.fluxx.log(functionString.substr(error.character - 40, 80), '                                       ^');
          }
        }
        return [uid, base.errorTemplate('eval')];
      }
    };

    base.errorTemplate = function(type) {
      var template = type == 'eval' ? base.options.evalErrorHTML : base.options.dataErrorHTML;
      return _.template(template);
    };

    base.render = function() {
      if (!base.data.stencil_form && !base.data.models) {
        base.options.callBack(base.options.noStencilHTML, base.$el);
        return;
      }
      if (base.data.models) {
        // List view master template is not cached (yet)
        base.parseMasterTemplate();
      } else if (base.reCalculateOnly) {
        base.reCalculate();
      } else if (base.cacheValid(base.data.stencil_form)) {
        base.parseTemplate(base.data.stencil_form.key);
      } else {
        base.loadTemplate(base.parseTemplate);
      }
    };

    base.setLoadingFlag = function(stencilForm) {
      $.fluxx.stencilsLoading[stencilForm.key] = [];
    };

    base.registerIfLoading = function(stencilForm, callBack) {
      return base.getLoadingFlag(stencilForm) && $.fluxx.stencilsLoading[stencilForm.key].push(callBack);
    };

    base.getLoadingFlag = function(stencilForm) {
      return $.fluxx.stencilsLoading[stencilForm.key] != undefined;
    };

    base.runLoadingCallbacks = function(stencilForm) {
      _.each($.fluxx.stencilsLoading[stencilForm.key], function(callBack) {
        callBack(stencilForm.key);
      });
      delete $.fluxx.stencilsLoading[stencilForm.key];
    };

    base.loadTemplate = function(callBack, stencilForm) {
      if (!stencilForm) stencilForm = base.data.stencil_form;
      var url;
      if (base.previewMode) {
        // Bypass cache mechanism when in preview mode
        var key = stencilForm.key.split('/');

        if (key.length < 3) key.unshift('GenericTemplate');

        url = '/stencil_forms/' + key[1] + '/' + base.options.stencilID + '.json';
      } else {
        if(base.options.stencilID) {
          var key = stencilForm.key.split('/');
          url = '/stencil_forms/' + key[1] + '/' + base.options.stencilID + '.json';
        } else {
            url = '/stencil_forms/' + stencilForm.key + '.json';
        }
      }
      if (!base.registerIfLoading(stencilForm, function() { callBack(stencilForm.key); })) {

        base.setLoadingFlag(stencilForm);

        //$.fluxx.log('Loading template: ' + url);
        $.get(url, function(data) {
          base.cacheTemplate(data);
        }).fail(function(status, xhr) {
          $.fluxx.log('Failure, falling back to cached template', status, xhr);
        }).complete(function() {
          callBack(stencilForm.key);
          base.runLoadingCallbacks(stencilForm);
        });
      }
    };

    base.parseMasterTemplate = function() {
      base.templateKey = 'list_master';
      var loading = [];
      _.each(base.data.models, function(item) {
        if (!base.cacheValid(item.stencil_form)) {
          if (_.indexOf(loading, item.stencil_form.key) == -1) {
            loading.push(item.stencil_form.key);
            base.loadTemplate(function (stencil) {
              loading = _.reject(loading, function(key) { return key == item.stencil_form.key;});
              if (loading.length == 0) {
                base.parseTemplate('list_master');
              }
            }, item.stencil_form);
          }
        };
      });
      if (loading.length == 0) base.parseTemplate('list_master');
    };

    base.parseTemplate = function(key) {
      var template = base.getCachedTemplate(key), output;
      base.templateKey = key;
      base.data.previewMode = base.previewMode? true : false;
      if (key == 'list_master') {
        // Listing view
        var model, output = '', data = base.data;
        for (var i = 0, len = base.data.models.length; i < len; i++) {
          base.data = data.models[i];
          output += base.recurseParse(base.getCachedTemplate(base.data.stencil_form.key).compiled);
        }
        base.data = data;
        base.data.model_templates = output;
      }
      if (template && template.compiled && $.isArray(template.compiled)) {
        if (!base.data.previewMode && base.data.model && base.data.model.deleted_at) {
          output = base.options.notFoundHTML;
        } else {
          if (base.options.elementUID) {
            base.data.show[base.options.elementUID] = true;
            output = base.safeParse(base.elementUids(base.options.elementUID).compiled);
          } else {
            output = base.recurseParse(template.compiled);
          }
          if (base.isStencilBlank()) {
            // If we are in preview mode and the template parses out to blank, show a message
            output = base.options.blankTemplateHTML;
          }
        }
        output = $.fn.accessibleHeadingHierarchy(output, base.options.modelType);
        delete base.data.model_templates;
      } else {
        output = base.options.noStencilHTML;
      }

      base.$el.one('fluxx-load', function(e) {
        base.$el.find('#user_login, #user_password').attr('autocomplete', 'off');
        base.$el
          .find('input#user_first_name, input#user_last_name')
          .addClass('linkable');
        base.toggleTableOfContentsViz();

        base.setReadOnly();
        if($.fluxx.config.dashboard_type !== 'config') {
          base.populateNotes();
        }
        base.setupTrackableAttributes();

        if (base.$el.data('model-type') != 'Loi') $.fn.fluxxMountReactComponent(base.$el);

        // Set the model ID for detail RTUs
        if (base.data.model && base.data.model.id) base.$el.data('model-id', base.data.model.id);

        if (!base.data.previewMode && base.data.calculations) base.attachHandlersForCalculatedFields();
        base.handlePendingCalculatedFields();
        if (base.saveScroll) {
          base.$el.find('.body').scrollTop(base.savedScroll);
        }
      });

      if (base.saveScroll) {
        base.savedScroll = base.$el.find('.body').scrollTop();
      }
      base.options.callBack(output, base.$el);
      base.toggleTableOfContentsViz();

      if (base.saveScroll) {
        base.$el.find('.body').scrollTop(base.savedScroll);
      }
      if (base.data.models) {
        if ($.fluxx.config.user_is_translator) {
          base.loadRTUListView(base.data)
        }
        var hits = _.select(base.data.models, function(m) {return m.cache_hit == true;});
        if (base.data.t) $.fluxx.log('--List View Render time: ' + base.data.t + '(' + hits.length +' from cache)');
      } else {
        if (base.data.t) $.fluxx.log('--Render time: ' + base.data.t + (base.data.cache_hit ? '(from cache)' : ''));
      }

      base.$el.fluxxCardArea().trigger("up.template-parsed", [{
        isInModal: base.isInModal(),
        formType: base.options.formType,
        modelType: base.options.modelType,
        modelId: base.options.modelID
      }]);
      base.lastParse = Date.now();
      if (base.lastResult) base.showErrors(base.lastResult);
      $.fn.fluxxMountReactComponent(base.$el);
    };

    base.toggleTableOfContentsViz = function() {
      var $card = base.$el.fluxxCard(),
          $modal = base.$el.parents('.modal');

      // if there is a modal, use the modal instead
      if ($modal[0]) {
        $card = $modal;
      }

      var $tocLinks = $card.find('.table-of-contents .scroll-to');
      return $tocLinks.each(function() {
        var $link = $(this);
        var uid = $link.attr('href');
        var $matchingGroup = $card.find('[data-uid="' + uid + '"]');
        if (!$matchingGroup[0] || $matchingGroup.is(':hidden')) {
          $link.hide();
        } else {
          $link.show();
        }
      });
    }

    base.recurseParse = function(elements) {
      // GM-30914 defensive code for a bad stencil
      // JS Error thrown: "Uncaught TypeError: Cannot read property 'uid' of undefined"
      if (!elements[elements.length - 1]) return '';
      var output = '',
          uid = elements[elements.length - 1].uid;
      if (uid && base.data.lazy && base.data.lazy[uid]) {
        output = base.setLazyLoad(uid, elements[elements.length - 1]);
      } else {
        for (var i = 0, len = elements.length, lastItem = elements.length - 1; i < len; i++) {
          if (i == lastItem) {
            base.data.elements = output;
            output = base.safeParse(elements[i]);
          } else {
            if ($.isArray(elements[i])) {
              output += base.recurseParse(elements[i]);
            } else {
              output += base.safeParse(elements[i]);
            }
          }
        }
        delete base.data.elements;
      }
      return output;
    };

    base.setLazyLoad = function(uid, element) {
      base.data.elements = [];
      return base.safeParse(element);
    };

    base.safeParse = function(code) {
      if (typeof code == 'object') code = code.code;
      try {
        return code(base.data);
      } catch(error) {
        var uid,
            ref = _.select(base.elementUids(), function(val, key) { var match = base.elementUids(key) && base.elementUids(key).compiled == code; if (match) uid = key; return match; })[0];
        $.fluxx.log('------------------------------------------------------------------------------------', 'A data error has occurred on element ' + uid);
        if (ref) $.fluxx.log('Component type: ' + ref.type);
        $.fluxx.log(error.message);
        return base.errorTemplate('data')(base.data);
      }
    };

      base.isStencilBlank = function() {
      // Assume the stencil is blank if no data is returned
      return !base.data || !base.options;
    };

    base.isInModal = function($area) {
      if ($area === undefined) $area = base.$el.fluxxCardArea();
      return $area.parents('.modal')[0] || $area.hasClass('modal');
    };

    base.scrollToTarget = function($area, $target, extraOffset) {
      var $scrollElem = $.fn.getScrollElem($target);
      if ($target.is('select') || $target.is('textarea')) {
        $target = $target.parents('.input');
      }
      $scrollElem.animate({
        scrollTop: $scrollElem.scrollTop() + $target.offset().top - $target.height() - $area.offset().top - extraOffset,
      }, 500);
    };

    base.findAndReplaceComplianceWarnings = function($area) {
      base.consolidatedErrorList = _.chain(base.consolidatedErrorList)
        .map(function(err) {
          var r = null;

          if (typeof err === 'string') {
            var targetSelector = null;

            if (err.startsWith('compliance_warnings|')) {
              var targetFieldName = err.split('|')[1].split(',')[0];
              targetSelector = '[name="' + base.formModelType() + '[' + targetFieldName + ']"]';
            } else if (err.startsWith('data_uid|')) {
              var targetFieldUID = err.split('|')[1];
              targetSelector = '[data-uid=' + targetFieldUID + ']';
            }

            if (targetSelector === null) {
              r = err;
            } else {
              r = $area.find(targetSelector);
            }
          } else if (err && err.length > 0) {
            r = err;
          }
          return r;
        })
        .reject(function(err) { return !err; })
        .value();
    };

    base.sortConsolidatedErrorList = function() {
      // Sort the consolidated error list by vertical offset so we have scrolling that moves linearly
      _.sortBy(base.consolidatedErrorList, function(field) {
        return field.offset().top;
      });
    };

    base.scrollToNextError = function($area) {
      if (!base.consolidatedErrorIndex && base.consolidatedErrorIndex !== 0) {
        // This is the first time we've jumped, so find all the compliance
        // warning fields, sort the error list, and then start at the first
        // error.
        base.consolidatedErrorIndex = 0;

        base.findAndReplaceComplianceWarnings($area);
        base.sortConsolidatedErrorList();
      } else {
        base.consolidatedErrorIndex = (base.consolidatedErrorIndex + 1) % base.consolidatedErrorList.length;
      }

      var errorBoxHeight = $area.find('div.error,.notice,[role=alert]').height(),
          $target = base.consolidatedErrorList[base.consolidatedErrorIndex];
      base.scrollToTarget($area, $target, errorBoxHeight);
    };

    base.scrollToPreviousError = function($area) {
      if (base.consolidatedErrorIndex === null) {
        /* This is the first time we've jumped, so start at the last error.
           Subtract two because we want to */
        base.consolidatedErrorIndex = base.consolidatedErrorList.length - 1;

        base.findAndReplaceComplianceWarnings($area);
        base.sortConsolidatedErrorList();
      } else {
        /* For some reason, JS doesn't actually implement modulo in a way that
          supports negative numbers? :facepalm: */
        base.consolidatedErrorIndex -= 1;
        if (base.consolidatedErrorIndex < 0) {
          base.consolidatedErrorIndex = base.consolidatedErrorList.length - 1
        }
      }

      var errorBoxHeight = $area.find('div.error,.notice,[role=alert]').height(),
          $target = base.consolidatedErrorList[base.consolidatedErrorIndex];

      base.scrollToTarget($area, $target, errorBoxHeight);
    }

    base.sortErrorMessages = function($area, data) {
      var result = {};
      if (data['success'] && data['success'] == true) return result;

      // Add Compliance Doc, Compliance Checklist, and Compliance Warnings to results
      _.each(data.errors, function (error, field) {
        if (error.includes('required documents') || error.includes('must be checked') || error[0].includes('compliance_warnings')) {
          result[field] = error;
        }
      });

      // Add Input field errors
      _.each($area.find(':input[name]'), function (obj, index) {
        var name = $(obj).attr('name');
        var field = name.replace(/\[\]$/g, '').replace(/.*\[/g, '').replace(/\]$/, '');
        if (field) {
          if(data.errors[field]) {
            result[field] = data.errors[field];
          } else if (data.errors[field.replace(/_lookup$/, '')]) {
            result[field] = data.errors[field.replace(/_lookup$/, '')];
          }
        }
      });
      return result;
    }

    // Display inline validation errors on form
    base.showErrors = function(data, showInFlash) {
      $.fluxx.log('Validation errors:', data.errors);
      var $area = base.$el.hasClass('area') ? base.$el : base.$el.parents('.edit, .pane');

      if (base.isInModal($area)) {
        $area.children().css('opacity', 1).parents('.modal').children().css('opacity', 1);
        $area.parent().css('opacity', 1).find('> .notice').remove();
        $area.parent().parent().find('.footer').css('opacity', 1);
      }

      if (base.formType() == "form") {
        delete base.lastResult;
        if (base.showInFlash) showInFlash = true;
        delete base.showInFlash;
        $area.find('form li.error').children('label').removeAttr("aria-label");
        $area.find('form li.error').removeClass('error');
        $area.find('.inline-errors').not('.input-mask-error').remove();
        $area.find('.form-element').removeClass('error');
        var errorText = ['Errors were found.  Error messages are displayed near each form field below. '];
        base.consolidatedErrorList = [];
        base.consolidatedErrorIndex = 0;
        if (showInFlash) errorText[0] = I18n.t('js.message.unable_to_promote_error') + ' ';

        // if this error has an existing translation, find the translation for the error message
        if (data.errors){
          for (var error in data.errors) {
            if (!I18n.t('js.text.' + error, error).includes(error + '" translation]')) {
              errorText.push("<br/>" + I18n.t('js.text.' + error, error) + " " + data.errors[error].join(', ') + '. ');
            }
          }
        }

        var complianceWarning = false,
            orderedErrorMessages = base.sortErrorMessages($area, data);

        if (_.isEmpty(orderedErrorMessages)) {
          /* orderedErrorMessages can be blank even if there is a compliance
          warning in data.errors. Check for that and set complianceWarning as
          appropriate */

          _.each(data.errors, function(errors) {
            var hasComplianceWarnings =  !_.chain(errors)
                .filter(function(msg) {
                  return /^compliance[ _]warnings/.test(msg); })
                .isEmpty()
                .value();

            complianceWarning = complianceWarning || hasComplianceWarnings;
          })
        }

        _.each(orderedErrorMessages, function (error, field) {
          var $field = $area.find(':input[name="' + data.model_type + '[' + field + ']"]');

          if (!$field[0]) $field = $area.find(':input[name="' + data.model_type + '[' + field + '_id]"]');
          if (!$field[0]) $field = $area.find(':input[name="' + data.model_type + '[' + field + '][]"]');
          // Considering the field inside might not be an input, but a data-uid linked to a div, we can access it directly
          if (!$field[0]) $field = $area.find('[data-uid="' + field + '"]');
          // hierarchicalElement are always hidden, to display the error, we must choose the parent element
          var hierarchicalElement = $field.parents('.hierarchical');
          if (hierarchicalElement.length) $field = hierarchicalElement;

          // wysiwyg elements are "disabled: hidden" and dont register with jquery "offset", this is a hack to get around
          // it (made more specific from original implementation of the hack)
          if ($field.is(":visible") === false && $field.hasClass('wysiwyg')) {
            base.consolidatedErrorList.push('data_uid|' + field);
          } else {
            if ($field.length > 1) {
              // filter out elements that are not visible
              $field = $field.filter(':visible')
            }
            base.consolidatedErrorList.push($field);
          }
          if ($field[0]) {
            $field.prev("label").attr("aria-label", _.unique(error));
            $field.addClass('error');
            // do not add inline errors to compliance warnings
            if (!error[0].includes("compliance_warnings")) {
              $field.closest('li').addClass('error').append('<p class="inline-errors" aria-label="' + field.replace(/_/g, ' ') + '">' + _.unique(error) + '</p>');
            }
            if (data.model_type === 'grantee_budget') {
              $field.parent().prev().find('.label').addClass('error').append('<p class="inline-errors" aria-label="' + field.replace(/_/g, ' ') + '">' + _.unique(error) + '</p>');
            }
          } else {
            $field = $area.find('.form-element[data-uid="' + field + '"]');
            $field.addClass('error');
            //TODO: Add validation error somewhere in the UI?
            //  .after('<p class="adhoc-inline-errors">' + _.unique(error) + '</p>');
          }
          $field.parents('.collapsible').addClass('open');
          if (showInFlash || !$field[0]) {
            _.each(_.unique(error), function(e) {
              if ((e && e.startsWith('compliance warnings')) || field.length == 36) {
                complianceWarning = true;

                if ((e && e.split('|').length > 1)) {
                  _.each(e.split('|')[1].split(','), function(fieldName) {
                    base.consolidatedErrorList.push(e);
                  });

                }
              } else {
                var $field = $area.find(':input[name="' + data.model_type + '[' + field + ']"]:visible');
                if (!$field[0]) $field = $area.find(':input[name="' + data.model_type + '[' + field + '_id]"]');
                if (!$field[0]) $field = $area.find(':input[name="' + data.model_type + '[' + field + '][]"]');

                var label = $($($field.parents('.input').find('.label')[0]).contents()[0]).text().trim();
                if ($field[0] && $($field[0]).attr('type') == 'checkbox' && !label){
                  label = $($field[0].nextSibling).text().trim();
                }
                // if this field is already listed in the errorText (most likely because it's listed as a translatable field)
                // then return and move to the next field in the list
                if (errorText.some(function (x) { return x.includes(label) && label !== ''; })) { return; }

                var headerPresentation = $($($field.parents('.input').find('h3[role="presentation"]')[0]).contents()[0]).text().trim();
                // use h3 presentation as the fallback if there is no label - PS: this is basically for hierarchical elements
                // that don't use label.
                var hField = _.humanize(label || headerPresentation || field);
                var delimiter = e == '' ? '. ' : ' ';
                var errorSuffix = e[e.length - 1] === '.' ? ' ' : '. ';
                errorText.push(hField + delimiter + e + errorSuffix);
              }
            });
          }
        });

        if (complianceWarning) {
          errorText.push(I18n.t('js.text.compliance_warning') + '. ');
        }

        // legacy components load after the validation. here, we check for fields that aren't loaded
        // and store their errors, we then check for the fields again when all fields are loaded.
        if (data.errors) {
          var errorsWithoutAvailableFields = {"errors": {}};
          var errorsWithAvailableFields = Object.keys(orderedErrorMessages);
          for (var err in data.errors) {
            if (!errorsWithAvailableFields.includes(err) && !errorsWithAvailableFields.includes(err + '_lookup')) {
              errorsWithoutAvailableFields["errors"][err] = data.errors[err];
            }
          }
          if (!_.isEmpty(errorsWithoutAvailableFields.errors)) {
            errorsWithoutAvailableFields["model_type"] = data.model_type;
            localStorage.removeItem("errors-without-available-fields");
            localStorage.setItem("errors-without-available-fields", JSON.stringify(errorsWithoutAvailableFields));
          }
        }

        if (base.consolidatedErrorList.length > 0) {
          var previousErrorText = I18n.t("js.text.previous_error")
          var nextErrorText = I18n.t("js.text.next_error")
          errorText.push('<div><a class="nav-to-prev-error" href="#">' + previousErrorText + '</a> :: <a class="nav-to-next-error" href="#">' + nextErrorText + '</a></div>');
        }

        base.consolidatedErrorIndex = null;

        errorText = errorText.join('');

        if (base.isInModal($area)) {
          var $body = $area.find('.body');
          if (!$body[0]) $body = $area.parents('.body');
          $body.find('.notice').remove();
          $body.prepend('<div class="notice error" role="alert" tab-index="-1"><a class="close-parent" href="#fluxx-card-notice"><img src="/images/fluxx_engine/theme/default/icons/cancel.png" alt="close"></a>' + errorText + '</div>').focus();
        } else {
          var $header = $area.find('> .header');
          $header.find('.notice').remove();
          $header.removeClass('empty').append('<div class="notice error" role="alert" tab-index="-1">' + errorText + '</div>').focus();
          $area.trigger('validation-error');
        }
        var hasRequestAmendmentError = data.errors && (data.errors.request_amendment_disabled || data.errors.request_amendment_limit_reached);
        if ($.fluxx.config.portal_user && hasRequestAmendmentError && $body.parent().hasClass('modal') && $body.parent().data('model-class') === "RequestAmendment" && $body.find('.error').length) {
          $body.find('.error').first().css("cssText", "display: block !important;");
        }

        if ($area.resizeFluxxCard) $area.resizeFluxxCard();
        $area.find('form').data('submitting', false);
      } else {
        base.$el.fluxxCard().showLoadingIndicator();
        base.lastResult = data;
        base.showInFlash = true;
        base.options.formType = 'form';
        base.setURL();
        base.skipNextRTU  = true;
        base.loadStencil(base.options.url, base.options.data);
      }
      if ($.my.api) {
        $.my.api.postValidationError(errorText);
      }
      $area.find('a.nav-to-prev-error').click(function(e) {
        var $area = $(this).fluxxCardArea();
        $.fluxx.util.itEndsHere(e);

        base.scrollToPreviousError($area);
      });

      $area.find('a.nav-to-next-error').click(function(e) {
        var $area = $(this).fluxxCardArea();
        $.fluxx.util.itEndsHere(e);

        base.scrollToNextError($area);
      });

      return $area;
    };

    base.formType = function() {
      if (base.options.formType == 'new') return 'form';
      return base.options.formType;
    };

    base.refreshElement = function(uid, options) {
      if (!uid) return false;

      if (!options) options = {};

      var $element = base.$el.find('[data-uid="' + uid + '"]').addClass('updating'),
          original = base.elementUids(uid).compiled,
          detailShowing = $element.hasClass('detail-showing'),
          $lazyLoader = $element.children('.lazy-load'),
          params = (options.toggle_detail && !detailShowing) || (!options.toggle_detail && detailShowing) ? {show_detail: true} : {};

      if (options.data) params = $.extend({}, params, options.data);
      if ($lazyLoader[0]) {
        $element.removeClass('updating');
        $lazyLoader.trigger('fluxx-load');
        return;
      }
      if (!base.data.model) return;

      // pass in 0 for the id for new models that are in form mode. (stencils_controller.rb:model:294)
      var id_str = base.data.model.id || "0";
      if (base.options.modelType == 'generic_template') id_str = base.options.modelID;
      var url = ['','stencils', base.data.model.class_name, base.formType(), id_str].join('/');
      if (options.lazy_load) {
        $element.append('<div class="loader"/>');
      }
      // if currently in native_view, add param for label translation
      if ($element.fluxxCard().hasClass('native_view')) { params['native_view'] = '1'; }

      $.get([url, uid].join('/'), params, function(data, status, xhr) {
        base.data = data;
        // we need this flag to avoid accessing values that don't exist it the data bundle after it has been reinitialized for a group
        base.data.model.reinitialized = true;
        if ($.isArray(original)) {
          output = base.recurseParse(original);
        } else {
          output = base.safeParse(original);
        }
        var $elemBeforeChange = $element;
        $element.replaceWith(output);
        $element = base.$el.find('[data-uid="' + uid + '"]');
        var editMode = base.$el.find('.body .edit').length;

        // If updating a hierarchical select field in edit mode, keep any unsaved model attribute choices
        if ($element.hasClass('hierarchical') && editMode) {
          var $unsavedSelections = $elemBeforeChange.find("[data-remove-from-ui=1]").closest('.hierarchical-item');
          $element.find('ul').append($unsavedSelections);
        }

        if (options.toggle_detail) {
          if (detailShowing) {
            $element.removeClass('detail-showing')
          } else {
            $element.find('.collapsible').addClass('open');
            $element.addClass('detail-showing');
          }
        } else {
          if (detailShowing) {
            $element.find('.collapsible').addClass('open');
            $element.addClass('detail-showing');
          }
        }

        // if a group has been closed before clicking on save and continue, keep it closed,
        var accordionList = $element.fluxxCardDetail().data('accordionList');
        if (accordionList && $element.fluxxCardDetail().data('is-save-and-continue') &&
           !$.isEmptyObject(accordionList) && accordionList[uid] && accordionList[uid] == 'close') {
            $element.addClass('loaded');
            $element.removeClass('open');
            delete accordionList[uid];
            $element.fluxxCardDetail().data('accordionList', accordionList);
        } else {
          $element.addClass('open loaded');
        }

        // load react component after partial refresh
        $.fn.fluxxMountReactComponent(".detail-open");
        base.populateNotes($element);
        base.setupTrackableAttributes($element);
        base.setReadOnly();
        if (!base.data.previewMode) base.attachHandlersForCalculatedFields();

        $element.areaDetailTransform({ suppressRevealIf: options.suppressRevealIf });
        if (options.onComplete) options.onComplete({ element: $element, accordionList: accordionList});
        $element.trigger('refresh.fluxx.element');
        if (typeof focusOnElementId !== 'undefined' && focusOnElementId != null) {
          $('#'+focusOnElementId).focus();
          focusOnElementId = null;
        }
        return $element;
      }).fail(function() {
        if (options.onError) options.onError();
      });
    };

    base.handlePendingCalculatedFields = function() {
      var selectorArr = _.map(base.data.waiting_for_calculations, function(element) {
        return '[data-uid="' + element.uid + '"]';
      });
      base.$el.find(selectorArr.join(",")).addClass('calc-pending');
    };

    base.attachHandlersForCalculatedFields = function() {
      // Put all valid calculations in order
      base.calculations = _.select(
          _.sortBy(_.compact(base.data.calculations), function(calc) { return calc.order; }), function(calc) {
        return _.some(calc.calculation.values) || calc.calculation.value;
      });


      if (base.savedCalculations) base.calculations = base.calculations.concat(base.savedCalculations);
      base.savedCalculations = base.calculations;

      // Generate a list of all attributes used in all calculations
      base.activeInputs = _.unique(_.reject(
        _.flatten(
          _.map(base.calculations, function(calc) {
            return (calc.calculation.values ? base.extractAttributes(calc.calculation.values) : []);
          }
        )
      ), function(item) {
          // There can't be inputs for related models on the form
        return item.match(/\./);
      }));

      // Attach handlers to any form inputs for attributes used in calculations
      _.each(base.activeInputs, function(input) {
        var $elem = base.$el.find(':input[name="' + base.formModelType() + '[' + input + ']"]');
        if ($elem[0]) {
          $elem.off('change keyup').on('change keyup', function(e) {base.reCalculate(e);});
        }
      });
    };

    base.formModelType = function() {
      if (base.options.modelType == 'granted_request' || base.options.modelType == 'fip_request') return 'grant_request';
      return base.options.modelType;
    };

    base.reCalculate = function(e) {
      // Run calculations in order
      _.each(base.calculations, function(calc) {
        var result;

        if (calc.calculation && calc.calculation.value) {
          result = base.data.model[calc.name];
        } else {
          // Run calculation, update value in data bundle and on the page
          result = base.formatResult(base.parseHash(calc.calculation), calc.type);

          // Save the result in the model hash for other calculations to use
          base.data.model[calc.name] = result;
        }
        // Update the value in the template
        var fieldName = base.formModelType() + '_' + calc.name;
        // Field data is slightly different in Branded Portal (New)
        // need to accommodate this
        var $elem = base.$el.find('#' + fieldName + ' .value')[0] ? base.$el.find('#' + fieldName + ' .value') : base.$el.find('[data-original-id=' + fieldName + ']' + ' .value');
        if ($elem[0]) {
          if (typeof result == 'boolean') {
            //TODO: Use true false values set in element settings
            $elem.html(result ? 'Yes' : 'No');
          } else {
            // Preserve currency symbol, TODO: rethink
            var replaceVal = $elem.html().replace(/[0-9\.\-\,]/g, '');
            replaceVal = replaceVal.replace(/infinity/ig, '');
            if (replaceVal == '%') $elem.html((result || 0) + replaceVal);
            else $elem.html(replaceVal + (result || 0));
          }
        }
      });
    };

    base.formatResult = function(result, type) {
      result = result || 0;
      if (type == 'integer') {
        return (parseInt(result) || 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      } else if (type == 'decimal') {
        return (parseFloat(result) || 0).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      } else if (type == 'boolean') {

        return result > 0;
      } else {
        return result;
      }
    };

    base.extractAttributes = function(values) {
      return _.compact(_.map(values, function(val) {
        if (val.values) {
          return base.extractAttributes(val['values']);
        } else if (typeof val == 'string') {
          return val;
        }
      }));
    };

    base.parseHash = function(calculation_hash) {
      var operation = base.toMath(calculation_hash.op),
        operands = calculation_hash.values;
      operands = operands.map(function(val) { return base.calculateOperand(val)});
      return operands.reduce(function(acc, val){
        var floatVal = typeof val == 'string' ? parseFloat(val.replace(/[^.0-9]/, '')) : val;
        var floatAcc = typeof acc == 'string' ? parseFloat(acc.replace(/[^.0-9]/, '')) : acc;
        return (typeof acc != 'undefined') ? base.executeOperation[operation](floatAcc, floatVal) : floatVal;
      })
    };

    base.calculateOperand = function(val){
      if(typeof val === 'object'){
        return base.parseHash(val)
      } else if(typeof val === 'string') {
        return base.getCurrentValue(val);
      } else {
        return val;
      }
    };

    base.numFromStr = function(rawStr) {
      var str = rawStr.replace(/[^0-9\.\-]/g, '');
      return str.match(/\./) ? parseFloat(str) : parseInt(str);
    };

    base.getCurrentValue = function(val) {
      var result = 0;
      if (!base.data.model.reinitialized) {
        if (eval('base.data.model.' + val)) result = eval('base.data.model.' + val);
      }
      if (typeof result == 'string') result = base.numFromStr(result);
      var $elem = base.$el.find(':input[name="' + base.formModelType() + '[' + val + ']"]');
      if ($elem[0]) result = base.numFromStr($elem.val());
      return result || 0;
    };

    base.toMath = function(operator){
      dictionary = {
        "plus"		: 	"+",
        "add"		: 	"+",
        "minus"		: 	"-",
        "sub"		: 	"-",
        "multiply"	:   "*",
        "mult"		: 	"*",
        "divide"	: 	"/",
        "div"		: 	"/"
      };
      return dictionary[operator.toLowerCase()] ? dictionary[operator.toLowerCase()] : operator
    };

    base.executeOperation = {
      '+': function(x,y) { return x + y },
      '-': function(x,y) { return x - y },
      '*': function(x,y) { return x * y },
      '/': function(x,y) { return x / y }
    };

    base.handleRTU = function(updatedIds) {
      if (base.skipNextRTU) {
        base.skipNextRTU = false;
        return;
      }
      if (!base.$el.is(':visible') || base.options.formType == 'new') return false;
      if (base.data && base.data.model && updatedIds.indexOf(base.data.model.id) == -1) return false;

      // [GM-39894] Prevent RTUs from unmounting a react component if a modal is currently open
      // better to skip a form refresh than to force close a modal because its parent was removed from the DOM
      var hasReactComponentChildren = base.$el.find(".flr-node").length !== 0;
      var reactModalIsOpen = $(".flr-modal").length !== 0;
      if (hasReactComponentChildren && reactModalIsOpen) return false;

      // [13837] checking against base.loading to ensure we don't set reCalculateOnly before our edit form
      // has loaded and we don't render the whole form and get stuck in forever-loading state.
      if (base.formType() == 'form' && !base.loading) base.reCalculateOnly = true;
      // Only refresh the detail view if this is a form or show (detail) and there is no simple modal visible right now
      if ((base.formType() == 'form' || base.formType() == 'show') && $('#simplemodal-container:visible').length == 0) {
        // Don't bring the card into focus when updating detail area due to RTU
        base.$el.fluxxCard().data('skipFocus', true);
        base.saveScroll = true;
        base.loadStencil(base.lastURL, base.lastData);
      }
    };

    // Run initializer
    base.init();
  };

  /**
   * Stencil states and a `callback` function that applies HTML to the jQuery element.
   */
  $.stencil.defaultOptions = {
    timeoutHTMLBanner: function(searchTimeout) {
      return $.Fluxx.templates.executiveSummary.searchTimeoutBanner(searchTimeout.staff_technical_support_email)
    },
    timeoutHTMLFlag: function(searchTimeout) {
      return $.fluxx.config.grantee_user
        ? $.Fluxx.templates.card.granteeSearchTimeoutFlag(searchTimeout.grantee_technical_support_email)
        : $.Fluxx.templates.card.internalSearchTimeoutFlag(searchTimeout.staff_technical_support_email);
    },
    errorHTML: '<div class="stencil error">' + I18n.t("js.message.stencil_error") + '</div>',
    evalErrorHTML: '<div class="stencil-component error">' + I18n.t("js.message.eval_error") + '</div>',
    dataErrorHTML: '<div class="stencil-component error">' + I18n.t("js.message.data_error") + '</div>',
    noStencilHTML: '<div class="stencil error">' + I18n.t("js.message.no_stencil_error") + '</div>',
    blankTemplateHTML: '<div class="stencil notice">' + I18n.t("js.message.blank_template_error") + '</div>',
    loadingHTML: '<div class="loading"/>',
    notFoundHTML: '<div class="show">' + I18n.t("js.message.not_found_error") + '</div>',
    /**
     * Apply the HTML to the jQuery element
     *
     * @param {string} data HTML to be applied to the element
     * @param {Object} $area jQuery element to apply the HTML to
     * @returns {undefined}
     */
    callBack: function(data, $area) {
      $area.html( data );
      $area.areaDetailTransform();
    }
  };

  $.fn.stencil = function(options){
    return this.each(function(){
      (new $.stencil(this, options));
    });
  };

  $.fn.extend({
    accessibleHeadingHierarchy: function(htmlString, modelType) {
      return modelType == 'generic_template' ? htmlString : htmlString.replace(/(<h)(\d[^>]*)>/g, '<h$2 role="presentation">');
    }
  });

  // Handlers
  $(window).delegate('.render-stencil', 'fluxx-load', function(e) {
    var $elem = $(this),
        $card = $elem.fluxxCard(),
        $reportConflict = $card.find('[name="report_conflict"]'),
        $form = $card.find('form:last');

    // Avant DB, modals are not contained in the card
    if (!$form[0]) $form = $('.modal.mo form:last');

    if ($elem.data('stencil-rendered')) return;
    $elem.data('stencil-rendered', true);

    //AML: Special case for the reviewer portal. Don't send request through stencils when a user clicks to report a conflict.
    $reportConflict.click(function(e) {
      $form.data('report_conflict', true);
    });

    $elem.stencil({
      disableNotes: true,
      adhoc: $elem.data('adhoc'),
      callBack: function(data, $area, xhr, status) {
        if ($card.data('stencilError')) {
          $elem.html(data);
        } else if ($elem.data('parent-tag')) {
          $elem.html($(data).find($elem.data('parent-tag')).html());
        } else {
          $elem.html(data);
        }
        $elem.removeClass('loading');

        if (!$.fluxx.config.current_user_id) {
          //AML: legacy components not supported in the simple portal for now
          $elem.find('.legacy-component').remove();
        }

        if ($elem.parents('[data-serialize-to-field]')[0]) {
          $elem.parents('[data-serialize-to-field]').parent().areaDetailTransform();
        } else {
          $elem.areaDetailTransform();
        }
        $elem.trigger('loaded.fluxx.stencil');
      }
    });

    if ($elem.data('stencil').options.formType == 'form' && $form[0]) {
      //AML: If we are in the portal, handle the submit here
      if (!$.fluxx.config.current_user_id) {
        $form.submit(function (e) {
          if ($form.data('report_conflict')) return;
          e.stopImmediatePropagation();
          e.preventDefault();
          if ($form.data('stencil-submitting')) return;

          if (typeof $form.valid == 'function') {
            if (!$form.valid()) return;
          }

          $form.find('[name="commit"]').val(I18n.t('js.text.submitting') + '...').prop('disabled', true);
          $form.data('stencils-submitting', true);

          // Do an ajax submit instead so we can catch validation errors
          var requestData = function(form) {
            return {
              url: form.attr('action').replace(/\.json(\?.*)?/g, '') + '.json',
              type: form.attr('method'),
              data: form.serializeForm(),
              success: function (data, status, xhr) {
                if (data.errors) {
                  $elem.data('stencil').showErrors(data);
                  form.data('stencil-submitting', false).data('submitting', false);
                  form.find('[name="commit"]').val('Submit Request').prop('disabled', false);
                } else {
                  if ($elem.parents('.modal')[0]) {
                    //AML TODO: Actually perform the data-on-success actions
                    $card.closeCardModal();
                  } else {
                    var location = data.location || form.attr('action');
                    var langRegex = /([&?]lang=)([^:/?#[\]@!$&'()*+,;=]*)(&|$)/;
                    var matchString = window.location.search.match(langRegex);
                    var extraLangParam = matchString ? '?lang=' + matchString[2] : ''
                    //AML: Redirect back to the form if the user passed the eligibility quiz
                    if (form.data('eligible')) {
                      window.location = location + '/edit' + extraLangParam;
                    } else if (form.data('eligible') == false) {
                      // used to help us inform screen-readers that next page we redirect to needs an alert role, otherwise this info is lost
                      var ariaAlertParam = '&aria_alert=true';
                      window.location = location + extraLangParam + ariaAlertParam;
                    } else if (data.location && data.location.includes('error=recaptcha_failure')) {
                      window.location = data.location
                    } else {
                      window.location = '/loi_created' + extraLangParam;
                    }
                  }
                }
              }
            }
          }

          if ($form.find('#recaptcha-loi').length > 0 && shouldUseRecaptcha) {
            grecaptcha.ready(function() {
              grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(function(token) {
                $form.append('<input name="recaptchaToken" value="' + token + '" type="hidden"/>')
                $.ajax(requestData($form));
              });
            });
          } else {
            $.ajax(requestData($form));
          }
        });
      } else {
        $form.attr('action', $form.attr('action').replace(/\.json(\?.*)?/g, '') + '.json');
        //AML: What if we want to render more than one stencil form in the same area in the future?
        $elem.fluxxCardArea().data('stencil', $elem.data('stencil'));
      }
    }
  });

  $(window).delegate('.legacy-component, .lazy-load', 'fluxx-load', function(e) {
    var $elem = $(this),
      url = $elem.data('render-url');

    if($elem.find('a.clone-template').length){
      return;
    }

    if (url) {
      var data = {};
      if ($elem.parent().hasClass('render-stencil-element')) {
        // This component is being rendered from the bulk update modal, pass a special param to legacy component
        data.bulk_action = 1;
      }

      $.get(url, data, function(data) {

        var modal = $elem.parents('.modal');

        if (modal.length) {
          var elems = modal.find('.wait-for-stencil:hidden');
          if (elems.length) {
            $.each(elems, function(index, value) {
              setTimeout(function() {
                var btn = $(value);

                btn.siblings('.loi-loading').remove();
                btn.removeClass('hidden');
              }, 500);
            });
          }
        }

        data = $.fn.accessibleHeadingHierarchy(data);

        $elem.html($(data).hide().fadeIn().areaDetailTransform()).removeClass('loading');
        $elem.find('#user_login, #user_password').attr('autocomplete', 'off');
        $elem.trigger('loaded.fluxx.component');
      }).fail(function() {
        $elem.html('<div class="stencil error">An error has occurred loading this component</div>');
      });
    }
  });

  $(window).delegate('.render-stencil-element', 'fluxx-load', function(e) {
    var $elem = $(this);

    if ($elem.data('loaded')) return;
    $elem.data('loaded', true);
    $elem.stencil({
      enableInteraction: true,
      disableNotes: true,
      adhoc: $elem.data('adhoc'),
      callBack: function(data, $area) {
        $area.html( data );
        $area.areaDetailTransform();
      }
    });
  });

})(jQuery);
